const TextImageSection = ({ title, text, imageUrl, altText, imageOnRight }) => {
  return (
    <div className="my-4 flex flex-col items-center bg-black p-4 text-white md:flex-row">
      {/* Conditionaly render image on the left */}
      {imageUrl && !imageOnRight && (
        <img
          src={imageUrl}
          alt={altText}
          className="h-auto p-6 md:w-2/3 lg:w-1/2"
        />
      )}
      {/* Text box */}
      <div className="flex-1 p-10">
        <h2 className="pb-10 font-goblin text-xl font-bold">{title}</h2>
        <p className="text-balance text-2xl font-thin tracking-wide lg:text-5xl xl:text-6xl">
          {text}
        </p>
      </div>
      {/* Conditionaly render image on the right */}
      {imageUrl && imageOnRight && (
        <img
          src={imageUrl}
          alt={altText}
          className="h-auto p-6 md:w-2/3  lg:w-1/2"
        />
      )}
    </div>
  );
};

export default TextImageSection;
