import React, { useRef } from "react";
import WeComeToYou from "./assets/WeComeToYouField.webp";
import NolansTruckTrailer from "./assets/NolansTruckTrailer.webp";
import JCBvalve from "./assets/JCBvalve.webp";
import LoveJob from "./assets/LoveJob.webp";
import TopSection from "./components/TopSection";
import TextImageSection from "./components/TextImageSection";
import StatisticCardsSection from "./components/StatisticCardsSection";
import PricingCardsSection from "./components/PricingCardsSection";
import FAQSection from "./components/FAQSection";
import Footer from "./components/Footer";
function App() {
  // Create a ref for the PricingCardsSection
  const pricingRef = useRef(null);
  // Create a ref for the faq section
  const faqRef = useRef(null);

  // Function to scroll to the PricingCardsSection
  const scrollToPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Function to scroll to the FAQSection
  const scrollToFaq = () => {
    faqRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="App mx-auto  bg-black">
      <TopSection
        title="We come to you!"
        phoneNumber="403-929-4882"
        scrollToPricing={scrollToPricing}
        scrollToFaq={scrollToFaq}
      />
      <TextImageSection
        title="Welcome to"
        text="Smith's Mobile Tire for all your tire needs, Servicing Warner, Lethbridge and area, Milk River, Coutts."
        imageUrl={NolansTruckTrailer}
        imageOnRight={true}
        altText="Inside Nolans Trailer"
      />
      <TextImageSection
        title="About Us"
        text="Repair shop that is open 24/7 on call, after hours."
        imageUrl={WeComeToYou}
        imageOnRight={false}
        altText="Inside Nolans Trailer"
      />
      <StatisticCardsSection />
      <PricingCardsSection ref={pricingRef} />
      <TextImageSection
        title=""
        text=""
        imageUrl={JCBvalve}
        imageOnRight={true}
        altText="JCB tire job done bad"
      />
      <TextImageSection
        title="Friendly recommendation jobs!"
        text="Thank you to everyone that recommends my services, I appreciate each and every one of you."
        imageUrl={LoveJob}
        imageOnRight={false}
        altText="A friendly recommendation job"
      />
      <FAQSection ref={faqRef} />
      <Footer />
    </div>
  );
}

export default App;
