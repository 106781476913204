const PricingCard = ({ title, price, services }) => {
  return (
    <div className="rounded-lg bg-neutral-950 p-4">
      <div className="text-xl font-medium">{title}</div>
      <div className="text-5xl font-bold">{price}</div>
      <ul className="list-disc pl-5 pt-5">
        {services.map((service, index) => (
          <li key={index} className="text-lg">
            {service}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PricingCard;
