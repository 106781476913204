import React from "react";
import Tire from "../assets/Tire-optimized.svg";
import tireTrack from "../assets/tiretiretire.png";
// import { isPointInPolygon } from "geolib";
// import RescueSection from "./rescueSection";

const TopSection = (props) => {
  // State variables
  // // Add state to control whether the rescue section is shown
  // const [showRescueSection, setShowRescueSection] = useState(false);
  // const [locationErrorMessage, setLocationErrorMessage] = useState("");
  // const [userLocation, setUserLocation] = useState(null);

  // // Geofence polygon
  // const polygon = [
  //   { latitude: 49.8907, longitude: -111.3221 }, // Bow Island
  //   { latitude: 50.4237, longitude: -113.3027 }, // Vulcan
  //   { latitude: 49.1772, longitude: -113.3547 }, // Cardston
  //   { latitude: 49.0002, longitude: -111.9515 }, //Coutts
  // ];

  // Style configurations
  const backgroundStyle = {
    backgroundColor: "#4A5568",
    backgroundImage: `url(${tireTrack})`,
    backgroundBlendMode: "multiply",
  };

  // const handleGetRescueClick = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         const isInsideGeofence = isPointInPolygon(
  //           { latitude, longitude },
  //           polygon, // This should be used directly since it's already an array of objects
  //         );

  //         if (isInsideGeofence) {
  //           setUserLocation({ latitude, longitude });
  //           setShowRescueSection(true);
  //           setLocationErrorMessage("");
  //         } else {
  //           setLocationErrorMessage(
  //             "Sorry, service is not available in this area.",
  //           );
  //         }
  //       },
  //       () => {
  //         setLocationErrorMessage("Unable to access your location.");
  //       },
  //     );
  //   } else {
  //     setLocationErrorMessage("Geolocation is not supported by this browser.");
  //   }
  // };

  // Function to close the RescueSection
  // const closeRescueSection = () => {
  //   setShowRescueSection(false);
  // };

  return (
    <div
      style={backgroundStyle}
      className="flex h-screen flex-col items-center justify-between p-10 text-zinc-100"
    >
      <img
        src={Tire}
        alt="A spinning tire with fire coming off"
        className="mt-10 h-[min(30vh,430px)] w-auto md:h-[min(40vh,430px)] lg:h-[min(50vh,430px)]"
      />
      <h1 className="text-center font-goblin text-3xl lg:text-6xl">
        {props.title}
        <br />
        <a
          href={`tel:${props.phoneNumber.replace(/\s/g, "")}`}
          className="mt-2 block text-xl text-inherit hover:text-inherit lg:text-4xl"
        >
          {props.phoneNumber}
        </a>
      </h1>

      <nav>
        <button
          onClick={props.scrollToFaq}
          className="mr-6 rounded-full bg-white px-5 py-4 text-black"
        >
          FAQ's
        </button>
        {/* {locationErrorMessage && (
          <div className="text-red-500">{locationErrorMessage}</div>
        )}

        {showRescueSection && (
          <RescueSection
            userLocation={userLocation}
            closeRescueSection={closeRescueSection}
          />
        )} */}

        <button
          onClick={props.scrollToPricing} // Use the passed function on click
          className="rounded-full bg-black px-5 py-4 text-white"
        >
          Shop Services
        </button>
      </nav>
    </div>
  );
};

export default TopSection;
