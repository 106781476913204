import React from "react";
import PricingCard from "./PricingCard";

const PricingCardsSection = React.forwardRef((props, ref) => {
  const cardsData = [
    {
      title: "Tire changeover from",
      price: "$32.50",
      services: [
        "Wheel removal",
        "Dismounting tires",
        "Mounting customer provided tires",
        "Airing to proper pressure",
        "Balancing",
        "Hand torque",
        "Hub cleaning",
        "TPMS reset",
        "Tire Shine - summer only",
      ],
    },
    {
      title: "Additional services from",
      price: "$20",
      services: [
        "Mobile rescue",
        "Wheel balancing",
        "Tire rotation",
        "Flat repairs",
        "Farm services",
        "Calcium Chloride / beet juice",
        "Super single's",
        "Semi's and trailers",
      ],
    },
  ];

  return (
    <div
      ref={ref}
      className="my-8 flex flex-col items-center bg-black text-white md:flex-row md:justify-center"
    >
      <div className="flex sm:space-x-1 md:space-x-5 lg:space-x-10">
        {cardsData.map((card, index) => (
          <PricingCard
            key={index}
            title={card.title}
            price={card.price}
            services={card.services}
          />
        ))}
      </div>
    </div>
  );
});

export default PricingCardsSection;
