import React from "react";

const FAQSection = React.forwardRef((props, ref) => {
  return (
    <section ref={ref} className=" bg-neutral-800 py-12">
      <div className="container mx-auto my-8 text-center text-neutral-200">
        <h2 className="mb-8 text-4xl font-semibold">
          Frequently Asked Questions
        </h2>
        <div className="flex flex-wrap justify-around">
          <div className="mb-8 w-full px-4 md:mb-0 md:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold">
              Do you bring jacks or have a big ramp?
            </h3>
            <p className="text-lg">
              I have everything I need in my trailer to raise your vehicle if
              neccesary.
            </p>
          </div>
          <div className="mb-8 w-full px-4 md:mb-0 md:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold">
              How far will you travel to a tire rescue?
            </h3>
            <p className="text-lg">Usually 100 kilometers is my limit.</p>
          </div>
          <div className="mb-8 w-full px-4 md:mb-0 md:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold">
              Can I buy tires from you?
            </h3>
            <p className="text-lg">
              Yes, call me for more information on specifics
            </p>
          </div>
          <div className="mb-8 w-full px-4 md:mb-0 md:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold">
              Can I buy tires from you?
            </h3>
            <p className="text-lg">
              Yes, call me for more information on specifics
            </p>
          </div>
          <div className="mb-8 w-full px-4 md:mb-0 md:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold">
              Can I buy tires from you?
            </h3>
            <p className="text-lg">
              Yes, call me for more information on specifics
            </p>
          </div>
          <div className="mb-8 w-full px-4 md:mb-0 md:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold">
              Can I buy tires from you?
            </h3>
            <p className="text-lg">
              Yes, call me for more information on specifics
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default FAQSection;
