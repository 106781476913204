import StatisticCard from "./StatisticCard";

const StatisticCardsSection = () => {
  const cardsData = [
    { icon: "🔧", number: "344", text: "Tire Repairs" },
    { icon: "🛞", number: "203", text: "Tire Changes" },
    { icon: "⭐", number: "4.8", text: "Average Rating" },
  ];

  return (
    <div className="flex flex-col items-center bg-neutral-700 p-20  text-white md:flex-row md:justify-center">
      <div className="flex space-x-8">
        {cardsData.map((card, index) => (
          <StatisticCard
            key={index}
            icon={card.icon}
            number={card.number}
            text={card.text}
          />
        ))}
      </div>
    </div>
  );
};

export default StatisticCardsSection;
