import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black py-8 text-white">
      <div className="container mx-auto text-center">
        <p className="mb-4 text-lg">
          © 2023 Smith's Mobile Tire Service. All Rights Reserved.
        </p>
        <div className="flex justify-center space-x-4">
          <a
            href="https://www.facebook.com/smithsmobiletire/"
            className="text-lg"
          >
            Facebook
          </a>
          {/* <a href="#" className="text-lg">
            Twitter
          </a>
          <a href="#" className="text-lg">
            LinkedIn
          </a>
          <a href="#" className="text-lg">
            Instagram
          </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
