const StatisticCard = ({ icon, number, text }) => {
  return (
    <div className="flex flex-col items-center rounded-lg bg-neutral-600 p-5">
      <div className="text-4xl">{icon}</div>
      <div className="font-bold sm:text-4xl md:text-5xl lg:text-7xl">
        {number}
      </div>
      <div className="text-xl lg:text-2xl">{text}</div>
    </div>
  );
};

export default StatisticCard;
